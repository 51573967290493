.validereInputBox .inputbox-inline {
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: none;
  padding-left: 5px;
  font-size: 14px;
}

.validereInputBox .input-group .inputbox-dropdown {
  border: 1px solid #ddd;
  border-left: none;
  box-shadow: none;
  padding-left: 5px;
  font-size: 14px;
}

.validereInputBox .input-group-addon {
  border: 1px solid #ddd;
  border-right: none;
  padding: 9px;
  color: #46c0c1;
}

.validereInputBox .inputbox-compress {
  border: 1px solid #ddd;
  border-radius: 3px !important;
  box-shadow: none;
  font-size: 14px;
  height: 33px;
  padding-left: 5px;
  width: 125px;
}
