.container {
  display: flex;
  flex-direction: column;
  min-width: 270px;
  gap: 16px;
  align-items: start;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
}

.banner {
  margin-bottom: 16px;
}

.details-panel {
  position: sticky;
  top: 24px;
  width: 100%;

  &[aria-expanded="true"] {
    min-width: 270px;
  }

  @media (min-width: 1024px) {
    max-width: 350px;
  }

  [data-role="content"] {
    padding: 16px;
  }
}

.data-panel {
  min-width: 0;
  max-width: 100%;
  flex-grow: 1;
  width: 100%;
}

.timeline-panel-button {
  height: 36px;

  &__active {
    height: 36px;
    border: 1px solid var(--colour-surface-200);
    background-color: var(--colour-surface-75);
  }
}

.timeline-panel {
  background-color: var(--colour-surface-50);

  &__header {
    border-bottom: 1px solid var(--colour-surface-200);
  }

  &__content {
    padding: 16px;

    &.no-overflow {
      overflow: hidden;
    }
  }

  &__list {
    display: "flex";
    flex-direction: "column";
    list-style: none;
    padding: 0;
  }
}

.comment-panel-content {
  background-color: var(--colour-surface-0);
  padding: 0;

  &.no-overflow {
    overflow: hidden;
  }
}

.comment-panel-button-toggle-group-container {
  display: flex;
  justify-content: center;
  background-color: var(--colour-surface-50);
  position: sticky;
  top: 0px;
  z-index: var(--zindex-sticky);
}

.comment-panel-button-toggle-group {
  width: 100%;
  margin: 16px 10px;
}

.comment-panel-row-comment-form-container {
  display: flex;
  margin: 8px 16px;
}

.comment-panel-row-accordion {
  [id*="header"] {
    padding: 4px 8px;
    background-color: var(--colour-surface-50);
    border-top: 1px solid var(--colour-surface-200);
    margin: 0;
    padding: 4px 16px 4px 8px;
    gap: 8px;
  }

  .comment-panel-row-title {
    margin-bottom: 0;
    color: var(--colour-text-600);
    font-size: 14px;
    font-weight: 600;
    line-height: 18.2px;
    letter-spacing: 0.14px;
  }

  [role="region"] {
    margin-top: 0;
  }
}

.comment {
  cursor: pointer;
}

.comments-empty-state {
  display: block;
  padding: 8px 0px 0px 0px;
}

.comment-panel-footer {
  display: flex;
  height: 300px;
  background-color: var(--colour-surface-50);
  padding: 8px 16px 16px 16px;
  border-top: 1px solid var(--colour-surface-200);
}

.play-flash-animation {
  animation: flash 1s ease-in-out;
}

@keyframes flash {
  from {
    background-color: var(--colour-primary-100);
  }
  to {
    background-color: initial;
  }
}
