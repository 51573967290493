.container {
  background: linear-gradient(
    to left,
    var(--colour-white) 90%,
    transparent 100%
  );
  // 5px is the padding of the account chip button background
  padding: 4px calc(24px - 5px);
  position: fixed;
  top: 0;
  right: 0;
  z-index: calc(var(--zindex-sticky, 1000) + 1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  width: 30%;
}

.settings-container {
  display: flex;
  align-items: center;
  gap: 2px;
}

.org {
  height: 100%;
  color: var(--colour-text-600, #4d6670);
  text-align: center;
  font-family: var(--font-family-sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  margin-right: 7px;

  &:has(.org-trigger) {
    margin-right: 2px;
  }

  .org-trigger {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    background: none;
    padding: 4px;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 6px;
    gap: 4px;

    &[aria-expanded="true"],
    &:hover {
      background-color: var(--colour-surface-200);
    }

    &:focus,
    &:active {
      outline: 2px solid var(--colour-primary-300);
    }
  }
}

.text-with-icon {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: 100%;

  &.warning {
    color: var(--colour-warning);
  }
}

.support-options {
  margin-top: 16px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.account-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.account-menu-banner {
  flex-grow: 1;
  width: 100%;
  height: 44px;
  border-radius: 6px 6px 0 0;
}

.account-menu-chip-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
  z-index: 1;
  width: 62px;
  height: 62px;
  pointer-events: none;
}

.account-menu-user-information-container {
  width: 100%;
  padding: 0 16px 0 16px;
  word-wrap: break-word;
  text-align: center;
}

.account-menu-account-name {
  color: var(--colour-text-800);
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  margin-bottom: 0;

  &.update-name {
    cursor: pointer;
  }
}

.account-menu-account-email {
  color: var(--colour-text-600);
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  margin-bottom: 0;
}

.account-menu-account-organization {
  color: var(--colour-text-400);
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  margin-bottom: 0;
}

.account-menu-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 16px 8px 16px;
}

.account-log-out-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 16px 16px 16px;
}

.account-menu-account-button {
  width: 100%;
}
