.option {
  &:hover {
    background-color: var(--colour-surface-100);
  }
}

.keyValueListContainer {
  border-radius: 6px;
  padding: 0px 12px;
  background-color: var(--colour-surface-50);
  border: 1px solid var(--colour-surface-200);
}

.skeleton--fill {
  width: 100%;
  height: 200px;
}

.attachSubmissionTrigger {
  margin-bottom: 12px;
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--colour-surface-50);
  border: 1px solid var(--colour-surface-200);
  border-radius: 6px;
  padding: 8px 16px 16px 16px;
  gap: 8px;

  .title {
    color: var(--colour-primary-500);
    font-size: 16px;
    font-weight: 600;
    line-height: 144%;
    letter-spacing: 0.18px;
    margin-top: 0;
    margin-bottom: 12px;
    width: 100%;
  }

  .emptyState {
    padding: 0px;
  }
}

.actionRow {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 8px;
}

.contentsContainer {
  padding: 8px 16px 16px 16px;
  border: 1px solid var(--colour-surface-200);
  border-radius: 6px;
}
