.checkbox-container {
  padding-top: 8px;
}
.dropdown-input-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}
.text-input {
  flex: 1;
}
.dropdown-input {
  flex: 3;
}
