.choice-section-container {
  max-width: 100%;
  background-color: var(--colour-surface-50);
  border: solid 1px var(--colour-surface-200);
  border-radius: 6px;
  padding: 8px 16px 16px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.taskComplete {
    pointer-events: none;
  }
}

.choice-section-title {
  color: var(--colour-primary-500);
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.16px;
  margin-bottom: 0px;
}

.choice-card-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.choice-card {
  width: 100%;
}
