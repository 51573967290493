.sectionContainer {
  margin-bottom: 16px;

  &.isRepeatable {
    margin-bottom: 8px;
  }
}

.sectionAddTrigger {
  margin-bottom: 16px;
}

.sectionPanel {
  margin-bottom: 0;
}

.panelContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
