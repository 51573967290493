.container {
  gap: 16px;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.input-panel {
  align-self: flex-start;
  border-radius: 8px;
  border: 1px solid var(--colour-surface-200);
  background: var(--colour-white);
  width: 100%;
  min-width: max-content;

  @media screen and (min-width: 1024px) {
    max-width: 300px;
    position: sticky;
    top: 24px;
  }

  &__header {
    padding: 16px 24px;
    border-bottom: 1px solid var(--colour-surface-200);
  }

  &__title {
    color: var(--colour-text-800);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__title-icon {
    color: var(--colour-primary-300);
    display: flex;
    align-items: center;
  }

  &__inputs {
    padding: 16px 24px;
  }

  &__footer {
    border-top: 1px dashed var(--colour-surface-200);
    padding: 16px 24px;
  }

  &__submit-triggers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}

.table-panel {
  max-width: 100%;
  min-width: 0;
  flex-grow: 1;
}

.banner {
  margin-bottom: 16px;
}
