.title {
  font: normal normal 600 18px/26px var(--font-family-sans);
  color: var(--colour-text-800);
  letter-spacing: 0.01em;
  margin-bottom: 12px;
}

.keyValueListContainer {
  border-radius: 6px;
  padding: 0px 12px;
  background-color: var(--colour-surface-75);
  border-color: #e6eaec;
}
