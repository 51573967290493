.marker {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 2px solid white;
  border-radius: 6px;
  box-shadow: var(--shadow-400);

  &--color-navy {
    background-color: var(--colour-primary-600);
    color: var(--colour-text-0);
  }

  &--color-poppy {
    background-color: var(--colour-error-light);
    color: var(--colour-error);
  }

  &:empty {
    padding: 10px;
  }
}

.label {
  padding: 4px 8px;
  margin-top: 8px;
  border-radius: 4px;
  background-color: var(--colour-surface-0);
  box-shadow: var(--shadow-400);
  color: var(--colour-text-800);
  text-align: center;
  leading-trim: both;
  text-edge: cap alphabetic;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 200%;
  letter-spacing: 0.12px;
}
