.tagList {
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.accordion-actions {
  display: flex;
  flex-direction: flex-row;
  gap: 8px;
}
