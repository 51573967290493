@import "constants/mixins";

.section-container {
  section {
    border-bottom: 1px solid var(--colour-surface-200);
    margin-bottom: 16px;
    .title {
      @include fontStyles(body, 16, true, 600);
      margin-bottom: 16px;
      display: inline-block;
    }
  }

  section:last-child {
    border-bottom: none;
  }
}
