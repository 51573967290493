@font-face {
  font-family: "IBM Plex Sans";
  src:
    local("IBM Plex Sans"),
    url("./fonts/IBMPlexSerif-Thin.woff") format("woff"),
    url("./fonts/IBMPlexSerif-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src:
    local("IBM Plex Sans"),
    url("./fonts/IBMPlexSans-Regular.woff") format("woff"),
    url("./fonts/IBMPlexSans-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src:
    local("IBM Plex Sans"),
    url("./fonts/IBMPlexSans-SemiBold.woff") format("woff"),
    url("./fonts/IBMPlexSans-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@keyframes skeleton {
  100% {
    transform: translateX(100%);
  }
}

html {
  &::-webkit-scrollbar-track {
    background: var(--colour-surface-100);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--colour-surface-300);
    border-radius: 2px;
  }
}

body {
  font-size: var(
    --font-size-0
  ); /* needs to be set or bootstrap will override the base font size */
  color: var(
    --colour-text-800
  ); /* needs to be set or bootstrap will override it */
  background: var(--colour-surface-50, #f9fafb);
  height: 100%;
  width: 100%;
}

/* Reverts bootstrap styling to use body */
.btn,
.dropdown-menu {
  font-size: 14px;
}

.alert-warning {
  background-color: var(--colour-warning);
}

.badge {
  padding: 4px 8px 4px;
}

.icon {
  margin-right: 5px;
}

.capitalized {
  text-transform: capitalize;
}

.nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.nowrap-2 {
  display: -webkit-box;
  /* truncates after 2 lines */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* won't work if parent has whitespace: nowrap and this inherits */
  white-space: break-spaces;
}

.buttonLink {
  box-shadow: none;
  padding: 0;
  color: var(--link-colour);
  display: inline-block;
  background: #fff;
  cursor: pointer;
}

.buttonLink:hover {
  background: #fff;
  color: var(--link-colour);
}

.buttonLink:focus {
  outline: none;
  background: #fff;
  color: var(--link-colour);
}

.actionButton {
  background-color: #15aab1;
  box-shadow: none;
  color: #ffffff;
}

.actionButton:hover {
  background-color: #15aab1;
  color: #ffffff;
}

.actionButton:focus {
  background-color: #15aab1;
  color: #ffffff;
}

.actionButton[disabled]:hover {
  background-color: #15aab1;
  color: #ffffff;
}
.dangerButton,
.dangerButton:hover,
.dangerButton:focus {
  background-color: #e51c23;
  box-shadow: none;
  color: #ffffff;
}

.cancelButton {
  border: 1px solid #ccc;
  box-shadow: none;
  float: left;
}

.defaultButton {
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: none;
  float: left;
}

.modal-title {
  font-size: 17px;
}

.modal-title .icon {
  color: var(--validere-colour);
  font-size: 20px;
}

.buttonRow {
  height: 35px;
}

.nav-tabs > li > a {
  color: var(--colour-text-600);
  box-shadow: inset 0 -2px 0 var(--colour-surface-200);
}

.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus:hover,
.nav-tabs > li > a:hover {
  color: var(--colour-text-800);
  box-shadow: inset 0 -2px 0 var(--colour-primary-100);
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
nav-tabs > li > a:focus {
  text-decoration: none;
  color: var(--colour-text-800);
  box-shadow: inset 0 -2px 0 var(--colour-primary-700);
}

.text-align-center {
  text-align: center;
}

.clipText {
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.disablePointerEvent {
  cursor: none;
  pointer-events: none;
}

.enablePointerEvent {
  cursor: pointer;
  pointer-events: auto;
}

.clickable {
  cursor: pointer;
}

.link {
  color: var(--link-colour);
  cursor: pointer;
}

.link:hover {
  text-decoration: none;
}

.linkableText {
  color: var(--text-colour);
  text-decoration: underline;
  cursor: pointer;
}

.linkableText:hover {
  color: var(--text-colour);
}

.wrap {
  white-space: normal;
}

.alertSuccess {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 3px;
  align-items: center;
}

.alertSuccess__icon {
  margin-right: 15px;
  font-size: 20px;
}

.alertWarning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 15px;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.verticalDivider {
  width: 1px;
  border-right: 1px solid var(--borderColour);
  margin: 0 20px;
}

.horizontalDivider {
  height: 1px;
  border-bottom: 1px solid var(--borderColour);
  margin: 20px 0;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: end;
  text-align: right;
}

.sectionTitle {
  color: var(--colour-text-800);
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 16px;
}

.sectionDescription {
  color: var(--colour-text-600);
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 16px;
}

.skeleton {
  display: inline-block;
  height: 25px;
  width: 70%;
  position: relative;
  overflow: hidden;
  background-color: var(--colour-surface-100);
  border-radius: 4px;
  font-size: 0px !important;
}

.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(#f2f5f6, 0) 0,
    rgba(#f2f5f6, 0.2) 20%,
    rgba(#f2f5f6, 0.5) 60%,
    rgba(#f2f5f6, 0)
  );
  animation: skeleton 1.5s infinite;
  content: "";
  animation-direction: alternate-reverse;
}

.sectionTitle {
  color: var(--colour-text-800);
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 16px;
}

.sectionDescription {
  color: var(--colour-text-600);
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 16px;
}

.skeleton {
  display: inline-block;
  height: 25px;
  width: 70%;
  position: relative;
  overflow: hidden;
  background-color: var(--colour-surface-100);
  border-radius: 4px;
  font-size: 0px !important;
}

.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(#f2f5f6, 0) 0,
    rgba(#f2f5f6, 0.2) 20%,
    rgba(#f2f5f6, 0.5) 60%,
    rgba(#f2f5f6, 0)
  );
  animation: skeleton 1.5s infinite;
  content: "";
  animation-direction: alternate-reverse;
}
