@import "constants/mixins";

.container {
  display: grid;
  gap: 10px;
  grid-template-rows: auto;

  @media (min-width: 1280px) {
    grid-template-rows: repeat(auto-fit, minmax(10px, 1fr));
  }
}

.filterPanel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.playground {
  display: grid;
  gap: 16px;
  grid-template-columns: auto;
  grid-template-areas:
    "inputPlayground"
    "previewPlayground"
    "contextPlayground";

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(10px, 1fr));
    grid-template-rows: repeat(2, minmax(10px, max-content));
    grid-template-areas:
      "inputPlayground previewPlayground"
      "inputPlayground contextPlayground";
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
    grid-template-rows: repeat(2, minmax(10px, max-content));
    grid-template-areas:
      "inputPlayground previewPlayground contextPlayground"
      "inputPlayground . .";
  }
}

.inputPlayground {
  grid-area: inputPlayground;

  @media (min-width: 1280px) {
    align-self: start;
    height: min-content;
  }
}

.inputPlaygroundContent {
  display: flex;
  flex-direction: column;
}

.previewPlayground {
  margin-bottom: 0;

  @media (min-width: 1280px) {
    margin-bottom: 16px;
  }
}

.contextPlayground {
  grid-area: contextPlayground;
}

.contextPlaygroundContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.titleWithFlair {
  display: flex;
  flex-direction: column;
}

.titleFlair {
  @include fontStyles(body, 12, false);

  font-style: italic;
  color: var(--colour-text-400);
  margin-top: 4px;
  line-height: 1;
}

.formInputWithInfo {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  // IMPROVE: this shouldn't be a thing but unfortunately for now has to - requires a refactor of the FormInputWrapper styling.
  & > *:first-child {
    margin-bottom: 0;
  }
}
