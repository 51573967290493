$control-button-spacing: 8px;

.controls {
  box-shadow: unset;
  display: flex;
  flex-direction: row;
  gap: $control-button-spacing;
  .top-controls {
    display: flex;
    gap: $control-button-spacing;
  }
}
.control-button {
  svg {
    max-width: unset;
    max-height: unset;
  }
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: var(--colour-text-600);
  border: 1px solid var(--colour-surface-200);
  border-radius: 6px;
  &.zoom-in {
    border-bottom: none;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }
  &.zoom-out {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    margin-bottom: $control-button-spacing;
  }
}

.fullscreen-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
