@keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}
.default {
  stroke-width: 1px;
  stroke: var(--colour-text-400);
  stroke-dasharray: unset;
  transition: stroke-width 0.2s ease-out;

  &.disabled {
    stroke-opacity: 0.5;
  }
  &.hover {
    stroke-width: 1.5px;
    stroke: var(--colour-text-600);
    stroke-dasharray: 12px;
    animation: dash 10s linear;
    animation-iteration-count: infinite;
    animation-direction: reverse;
  }
}

.label {
  .icon-container {
    flex-basis: 16px;
    height: 16px;
  }
  position: absolute; // This is important for placing the edge label on the right spot
  max-width: 160px;
  border-radius: 6px;
  height: 24px;
  background-color: white;
  border: 1px solid white;
  padding: 0 4px;
  font-size: 12px;
  &.hover {
    border-color: var(--colour-text-600);
    z-index: 100;
  }
  &.disabled {
    opacity: 0.5;
  }
}

.label-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
